import { graphql } from 'gatsby';
import React, { FC } from 'react';

import App from '../components/App';
import Button from '../components/Button';
import Posts from '../components/Posts';

// markup
const IndexPage: FC<{
  data: any;
}> = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
    <App>
      <Posts edges={edges} />
      <Button to="/articles/">Read more</Button>
    </App>
  );

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { slug: { regex: "/article/" } } }
      limit: 20
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 140)
          frontmatter {
            slug
            date(formatString: "MMMM DD, YYYY", locale: "en")
            title
            category
            tag
            imageCreditName
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;
